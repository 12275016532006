const fields = [
    { key: 'index', label: "No", _style:'min-width:50px'},
    { key: 'select', label: "select", _style:'min-width:50px' },
    { key: 'nama', label: "Nama Hewan", _style:'min-width:100px;' },
    { key: 'dibuat_oleh', label: "Dibuat Oleh", _style:'min-width:100px;' },
    { key: 'tanggal_input', label: "Tanggal Input", _style:'min-width:100px;' },
    { key: 'action', label: 'Aksi', _style: 'min-width:75px'}
]


export { fields }