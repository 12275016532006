<template>
    <div>
      <CCard>
        <CCardBody>
          <div class="row justify-content-between">
            <div class="col-10">
              <div class="row mb-3">
                <button
                  class="btn btn-sm btn-primary ml-2"
                  v-if="user.role.name.toLowerCase() == 'admin'"
                  @click="addModal()"
                >
                  <CIcon name="cilMedicalCross" />
                  Tambah
                </button>
  
                <button
                  class="btn btn-sm btn-primary ml-2"
                  v-if="user.role.name.toLowerCase() == 'admin'"
                  @click="addModalImport()"
                >
                  <CIcon name="cilArrowThickToBottom" />
                  Import
                </button>
              
                <!-- <button
                    class="btn btn-sm btn-success mr-2 ml-2"
                  >
                    <CIcon name="cil-spreadsheet" />
                    Export Excel
                </button>
                <button class="btn btn-sm btn-danger">
                  <CIcon name="cib-adobe-acrobat-reader" />
                  Export PDF
                </button> -->
               
                <label class="m-1 ml-3" for="">Select All : </label>
                <input
                  type="checkbox"
                  v-model="isSelectedAll"
                  @change="checkAll()"
                />
  
                <select v-if="selectedItems.length > 0"
                    style="max-width: 200px"
                    class="form-control form-control-sm mx-2"
                    placeholder="Ketik disini"
                    v-model="selectedAction"
                    @change="changeActionSelected()"
                  >
                  <option value="0">Action Selected</option>
                  <option value="1">Delete Items Selected</option>
                  <option value="2">Export Excel Items Selected</option>
                  <option value="3">Export Pdf Items Selected</option>
                </select>
  
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-10">
              <div class="row mb-3">
                <label class="m-1 ml-3" for="">Search : </label>
                <input
                  type="text"
                  v-model="params.keyword"
                  style="max-width: 175px"
                  class="form-control form-control-sm mx-2"
                  placeholder="Ketik disini"
                />
                <button @click="search()" class="btn btn-sm btn-success">
                  Cari
                </button>
              </div>
            </div>
            <div class="col-2">
              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Per Halaman</label
                      >
                    </div>
                    <select
                      class="custom-select"
                      style="max-width: 100px"
                      id="inputGroupSelect01"
                      v-model="params.row"
                      @change="getData()"
                    >
                      <!-- <option selected>Pilih...</option> -->
                      <option selected value="500">500</option>
                      <option value="1000">1000</option>
                      <option value="1500">1500</option>
                      <option value="2000">2000</option>
                      <option :value="total">All</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="isSearching">
            <div class="col">
              <h3>
                <span class="badge bg-primary text-light text-bor my-auto">
                  {{searchOn}}&nbsp;&nbsp;
                  <span @click="searchOff" class="badge bg-light text-dark text-center" style="cursor: pointer">X</span>
                </span>
              </h3>
            </div>
          </div>
          <!-- start table -->
          <CDataTable
            class="table-striped"
            :items="computedItems"
            :fields="fields"
            sorter
          >
          <template #select="{ item }">
            <td class="py-2">
              <input 
                  type="checkbox"
                  @change="check(item)"
                  v-model="item.select"
                />
            </td>
          </template>
            <template #action="{ item }">
              <td class="py-2">
                <CButton
                  @click="edit(item)"
                  class="mr-2"
                  color="warning"
                  square
                  size="sm"
                >
                  Edit
                </CButton>
                <CButton @click="hapus(item)" color="danger" square size="sm">
                  Delete
                </CButton>
              </td>
            </template>
          </CDataTable>
          <pagination
            v-if="total !== items.length"
            v-model="page"
            :records="total"
            :per-page="500"
            @paginate="pagination"
          />
        </CCardBody>
      </CCard>
      <!-- Start modal -->
      <CModal
        size="lg"
        :title="isUpdate ? 'Edit Jenis Hewan' : 'Tambah Jenis Hewan'"
        centered
        :color="isUpdate ? 'success' : 'primary'"
        :show.sync="createModal"
      >
      <div>
        <div class="row">
          <div class="col-4">Nama Hewan</div><div class="col-8"><CInput v-model="form.nama" placeholder="nama hewan"/></div>
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="cancel" class="btn btn-secondary mr-3">Batal</button>

          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Simpan
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update
          </button>
        </div>
      </template>
      </CModal>
      <!-- Import modal -->
      <CModal
          size="lg"
          title="Import Data"
          centered
          color="primary"
          :show.sync="createModalImport"
        >
        <div class="row">
          <div class="col">
            <a href="jenis-hewan.xlsx" download class="btn btn-primary mb-3">Download format</a>
            <div class="div">
              <input
                type="file"
                class="form-control"
                ref="importData"
                @change="importData"
              />
            </div>
          </div>
        </div>
        <template slot="footer">
        <div>
          <button @click="cancelImport" class="btn btn-secondary mr-3">Batal</button>

          <button @click="submitImport" class="btn btn-primary">
            Import
          </button>
        </div>
      </template>
      </CModal>
    </div>
  </template>
  
  
  
<script>
  import * as data from "../../model/list-jenis-hewan";
  import { uploadImage } from "@/utils/fileUpload";
  import FileSaver from "file-saver";

  export default {
    data() {
      return {
        createModal: false,
        createModalImport: false,
        modalFields: [],
        fields: [],
        isUpdate: false,
        items: [],
        imageListAfter: [],
        imageListProcess: [],
        imageListBefore: [],
        selectedItems: [],
        isSelectedAll: false,
        selectedAction: 0,
        page: 1,
        total: 0,
        form: {
          nama: "",
          user_id: ""
        },
        user: JSON.parse(localStorage.getItem("user")),
        params: {
          sorttype: "desc",
          sortby: "id",
          row: 500,
          page: 1,
          type: [0, 1],
          keyword: "",
        },
        isSearching: false,
        searchOn: '',
        file: ""
      };
    },
    methods: {
      cancelImport(){
        this.file = ''
        this.createModalImport = false

      },
      submitImport(){
        let formData = new FormData()
        formData.append('file', this.file)
        var loading = this.$loading.show();
        this.$store
          .dispatch('jenis_hewan/importFile', formData)
          .then(() => {
            this.$toast.success("Berhasil import data");
            this.createModalImport = false
            loading.hide();
            this.file = ""
            this.getData()
            // console.log(resp)
          })
          .catch(err => console.log(err))
      },
      search() {
        if (this.params.keyword != "") {
          this.isSearching = true;
          this.getData();
          this.searchOn = this.params.keyword;
          // this.params.keyword = '';
        } else {
          this.$toast.error("Inputan tidak boleh kosong !!");
        }
      },
  
      searchOff(){
        this.isSearching = false;
        this.params.keyword = '';
        this.getData();
      },
      submit() {
  
        this.form.user_id = JSON.parse(localStorage.getItem("user")).id;
        this.form.opd_id = JSON.parse(localStorage.getItem("user")).role_id;
        var loading = this.$loading.show();
        this.$store
          .dispatch("jenis_hewan/addJenisHewan", this.form)
          .then(() => {
            this.$toast.success("Berhasil menambahkan data");
            loading.hide();
            this.createModal = false;
            this.form = {};
            this.getData();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      },
      edit(item) {
        this.form = item;
        this.isUpdate = true;
        this.createModal = true;
        // this.imageListBefore = this.extractGallery(item.paket_pekerjaan_befores);
        // this.imageListProcess = this.extractGallery(item.paket_pekerjaan_processes);
        // this.imageListAfter = this.extractGallery(item.paket_pekerjaan_afters);
      },
      cancel() {
        this.form = {
          type: 0,
        };
        this.createModal = false;
      },
      update() {
        var loading = this.$loading.show();
        delete this.form.updated_at;
        this.form.user_id = JSON.parse(localStorage.getItem("user")).id;
        this.$store
          .dispatch("jenis_hewan/updateJenisHewan", {
            id: this.form.id,
            data: this.form.nama,
            user_id: this.form.user_id
          })
          .then(() => {
            this.$toast.success("Berhasil merubah data ");
            loading.hide();
            this.createModal = false;
            this.form = {
              type: 0,
            };
            this.getData();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      },
      hapus(item) {
        if (confirm("Data akan dihapus !!")) {
          this.$store
            .dispatch("jenis_hewan/deleteJenisHewan", item.id)
            .then(() => {
              this.$toast.success("Berhasil menghapus data ");
  
              this.form = {
                type: 0,
              };
              this.getData();
            })
            .catch((e) => {
              this.$toast.error(e);
              this.$loading.hide();
            });
        }
      },
      getData() {
        var loading = this.$loading.show();
        this.$store
          .dispatch("jenis_hewan/getJenisHewan", this.params)
          .then((resp) => {
            this.items = resp.data.data;
            this.total = resp.data.total;
  
            // khusus untuk checkbox
            this.selectedItems = [];
            this.items.forEach(element => {
              if (this.isSelectedAll) {
                element.select = true;
                this.selectedItems.push(element.id);
              } else {
                element.select = false;
              }
            });
            loading.hide();
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      addModal() {
        this.isUpdate = false;
        this.createModal = true;
      },
      addModalImport() {
        console.log("awkwkkw");
        this.createModalImport = true;
      },
      pagination(page) {
        this.page = page;
        this.params.page = page;
        this.getData();
      },
      uploadAfter() {
        this.$refs.uploadFieldAfter.click();
      },
      selectFileAfter(event) {
        this.file = event.target.files[0];
        var loading = this.$loading.show();
        uploadImage(this.file)
          .then((resp) => {
            this.imageListAfter.push(resp);
            loading.hide();
            alert("File berhasil diupload !!");
          })
          .catch((e) => {
            loading.hide();
            alert("Terjadi kesalahan !! | " + e);
          });
      },
      uploadProcess() {
        this.$refs.uploadFieldProcess.click();
      },
      importData($event) {
        console.log($event, "event");
        this.file = event.target.files[0];
        // this.$loading.show();
        
      },
      selectFileProcess(event) {
        this.file = event.target.files[0];
        var loading = this.$loading.show();
        uploadImage(this.file)
          .then((resp) => {
            this.imageListProcess.push(resp);
            loading.hide();
            alert("File berhasil diupload !!");
          })
          .catch((e) => {
            loading.hide();
            alert("Terjadi kesalahan !! | " + e);
          });
      },
      uploadBefore() {
        this.$refs.uploadFieldBefore.click();
      },
      selectFileBefore(event) {
        this.file = event.target.files[0];
        var loading = this.$loading.show();
        uploadImage(this.file)
          .then((resp) => {
            this.imageListBefore.push(resp);
            loading.hide();
            alert("File berhasil diupload !!");
          })
          .catch((e) => {
            loading.hide();
            alert("Terjadi kesalahan !! | " + e);
          });
      },
      deleteImageBefore(state, index) {
        this.imageListBefore.splice(index, 1);
      },
      deleteImageProcess(state, index) {
        this.imageListProcess.splice(index, 1);
      },
      deleteImageAfter(state, index) {
        this.imageListAfter.splice(index, 1);
      },
      extractGallery(item) {
        let data = [];
        item.forEach(element => {
          data.push(element.image);
        });
        return data;
      },
      check(item) {
        if (item.select) {
          this.selectedItems.push(item.id);
        } else {
          const index = this.selectedItems.indexOf(item.id);
          this.selectedItems.splice(index, 1);
        }
      },
      checkAll() {
        this.getData();
      },
      changeActionSelected() {
        console.log(this.selectedAction)
        switch (Number(this.selectedAction)) {
          case 1:
            console.log('deleted')
            this.deleteSelected('delete');
            break;
          case 2:
            console.log('export excel')
            this.exportExcel('export_excel');
            break;
          case 3:
            console.log('export pdf')
            this.exportPDF();
            break;
        }
      },
      deleteSelected(action) {
        var loading = this.$loading.show();
        this.$store
          .dispatch("jenis_hewan/selectedAction", 
          {
            action: action,
            data: this.selectedItems,
          })
          .then(() => {
            this.$toast.success("Item Selected Berhasil diproses");
            loading.hide();
            this.createModal = false;
            this.form = {
            };
            this.getData();
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      openModalExcel() {
        this.exportModal = true;
        this.exportType = "Export Excel";
      },
      openModalPDF() {
        this.exportModal = true;
        this.exportType = "Export PDF";
      },
      exportExcel() {
        var loading = this.$loading.show();
        // console.log(this.selectedItems)
        this.$store
         .dispatch("jenis_hewan/exportReport", {
            data: this.selectedItems,
          })
          .then((resp) => {
            loading.hide();
            FileSaver.saveAs(
              resp.data,
              "Laporan_Harian_"
            );
            this.exportModal = false;
            this.exportDataParams = {};
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      },
      exportPDF() {
        this.$router.push({ name: "ExportJenisHewanPdf", query: { data: this.selectedItems } });
      },
    },
    computed: {
      computedItems() {  
        return this.items.map((item, index) => {
          return {
            index: index+1 + '.',
            ...item,
            // nilai_kontrak: item.nilai_kontrak.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            // full_name: item.user != null ? item.user.full_name : "",
            // opd: item.role != null ? item.role.name : "",
            // created_at: item.created_at.slice(0, 10),
            dibuat_oleh: JSON.parse(localStorage.getItem("user")).full_name,
            tanggal_input: this.$moment(item.created_at).format("Do MMMM YYYY"),
          };
        });
      },
    },
    mounted() {
      this.getData();
    },
  
    created() {
      this.fields =
        this.user.role.name.toLowerCase() == "admin"
          ? data.fields
          : data.fields_no_action;
    },
  };
</script>

<style scoped>
.text-bold {
  font-weight: bold;
}
</style>